// import { colors } from '@material-ui/core';

const palette = {
  primary: {
    // light: will be calculated from palette.primary.main,
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
    main: '#44C606',
    white: '#FFFFFF',
    black: '#000000',
    topHeader: '#3CAD07',
    bottomHeader: '#44C606'
  },
  blanco: {
    main: '#ffffff'
  },
  negro: {
    main: '#000000'
  },
  cafe: {
    main: '#976F53'
  },
  rosado: {
    main: '#EB008B'
  },
  rojo: {
    main: '#C12A21'
  },
  amarillo: {
    main: '#FBC527'
  },
  azul: {
    main: '#013171'
  },
  naranjo: {
    main: '#FF5C00'
  },
  verde: {
    main: '#009A35'
  },
  celeste: {
    main: '#009EE5'
  },
  celeste2: {
    main: '#00AEF9'
  },
  dorado: {
    main: '#B7982A'
  },
  morado: {
    main: '#860085'
  },
  gris: {
    main: '#857774'
  },
  verde2: {
    main: '#009F9A'
  },
  rojo2: {
    main: '#B11834'
  }
};

export default palette;
