import escalatorDown from '../assets/images/lottie/escalator_down.json';
import escalatorUP from '../assets/images/lottie/escalator_up.json';
import elevatorDown from '../assets/images/lottie/elevator_down.json';
import elevatorUp from '../assets/images/lottie/elevator_up.json';
import stairsDown from '../assets/images/lottie/stairs_down.json';
import stairsUp from '../assets/images/lottie/stairs_up.json';

export const escalatorDownLottie = {
  loop: true,
  autoplay: true,
  animationData: escalatorDown,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const escalatorUPLottie = {
  loop: true,
  autoplay: true,
  animationData: escalatorUP,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const elevatorDownLottie = {
  loop: true,
  autoplay: true,
  animationData: elevatorDown,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const elevatorUpLottie = {
  loop: true,
  autoplay: true,
  animationData: elevatorUp,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
export const stairsDownLottie = {
  loop: true,
  autoplay: true,
  animationData: stairsDown,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const stairsUpLottie = {
  loop: true,
  autoplay: true,
  animationData: stairsUp,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
