import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { enUS } from '@material-ui/core/locale';

import overrides from './overrides';
import palette from './palette';

export const theme = responsiveFontSizes(
  createMuiTheme(
    {
      overrides,
      palette
    },
    enUS
  )
);
